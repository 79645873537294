<template>
  <div>
    <c-table
      title="자동으로 높이는 위아래 맞춰서 setting"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        버튼 영역
      </template>
    </c-table>
    <c-table
      class="q-pt-md"
      title="높이 직접 setting setting"
      :gridHeight="grid.height"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
    >
    </c-table>
    <c-table
      class="q-pt-md"
      title="컴포넌트 넣기"
      :gridHeight="grid2.height"
      :columns="grid2.columns"
      :data="grid2.data"
      @linkClick="linkClick"
    >
    </c-table>
    <c-table
      class="q-pt-md"
      ref="userTable"
      title="선택 목록"
      :columns="grid3.columns"
      :data="grid3.data"
      :gridHeight="grid3.height"
      :selection="selection"
      rowKey="userId"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-toggle
          :label="selection"
          v-model="selection"
          color="primary"
          keep-color
          false-value="single"
          true-value="multiple"
        />&nbsp;
        <q-btn-group outline >
          <c-btn label="선택" icon="check" @btnClicked="select" />
        </q-btn-group>
      </template>
    </c-table>
    <c-table
      class="q-pt-md"
      title="멀티 헤더"
      :gridHeight="grid4.height"
      :columns="grid4.columns"
      :data="grid4.data"
      :merge="grid.merge"
    >
    </c-table>
    <c-tree-table
      class="q-pt-md"
      title="tree 테이블"
      parentProperty="pid"
      customID="id"
      :gridHeight="grid5.height"
      :columns="grid5.columns"
      :data="grid5.data"
      :expandAll="true"
    >
    </c-tree-table>
  </div>
</template>

<script>
export default {
  name: 'stepper',
  props: {
    popupParam: {
      type: Object,
      default: () => ({

      }),
    },
  },
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: 'plantNm' }
        ],
        columns: [
          {
            name: 'plantNm',
            field: 'plantNm',
            label: '사업장',
            align: 'center',
            sortable: true,
            // rowspan: 5,
          },
          {
            name: 'chngStepNm',
            field: 'chngStepNm',
            label: '진행단계',
            align: 'center',
            sortable: true
          },
          {
            name: 'bizNm',
            field: 'bizNm',
            label: '제목',
            align: 'left',
            sortable: true,
          },
          {
            name: 'bizApprStepNm',
            field: 'bizApprStepNm',
            label: '결재진행단계',
            align: 'center',
            sortable: true
          },
          {
            name: 'chngAttNm',
            field: 'chngAttNm',
            label: '변경구분',
            align: 'center',
            sortable: true
          },
          {
            name: 'chngNum',
            field: 'chngNum',
            label: '문서번호',
            align: 'center',
            sortable: true
          },
          {
            name: 'rqstDeptNm',
            field: 'rqstDeptNm',
            label: '요청부서',
            align: 'center',
            sortable: true
          },
          {
            name: 'rqstUserNm',
            field: 'rqstUserNm',
            label: '요청자',
            align: 'center',
            sortable: true
          },
          {
            name: 'confirmDt',
            field: 'confirmDt',
            label: '완료일',
            align: 'center',
            sortable: true
          },
        ],
        data: [],
        height: '362px'
      },
      grid2: {
        columns: [
          {
            name: 'Thousand',
            field: 'Thousand',
            label: '천단위 콤마',
            align: 'center',
            sortable: true,
            type: 'cost',
          },
          {
            name: 'link',
            field: 'link',
            label: '링크',
            align: 'left',
            sortable: true,
            type: 'link',
          },
          {
            name: 'text',
            field: 'text',
            label: '텍스트',
            align: 'center',
            sortable: true,
            type: 'text',
          },
          {
            name: 'select',
            field: 'select',
            valueName: 'selectCd',
            label: '셀렉트 박스',
            align: 'center',
            sortable: true,
            type: 'select',
            comboItems: [
              { code: '1', codeName: '위원회' },
              { code: '2', codeName: '검토자' },
              { code: '3', codeName: '전문가' },
            ],
          },
          {
            name: 'textarea',
            field: 'textarea',
            label: '텍스트에어리어',
            align: 'center',
            sortable: true,
            type: 'textarea',
          },
          {
            name: 'user',
            field: 'user',
            label: '사용자 지정',
            align: 'left',
            sortable: true,
            type: 'user',
            userId: 'userId',
          },
          {
            name: 'check',
            field: 'check',
            label: '체크박스',
            align: 'center',
            sortable: true,
            type: 'check',
          },
          {
            name: 'date',
            field: 'date',
            label: '달력',
            align: 'center',
            sortable: true,
            type: 'date',
          },
          {
            name: 'dept',
            field: 'dept',
            label: '부서',
            align: 'center',
            sortable: true,
            type: 'dept',
            deptCd: 'deptCd',
          },
        ],
        data: [],
        height: '362px'
      },
      grid3: {
        columns: [
          {
            name: 'plantNm',
            field: 'plantNm',
            label: '사업장',
            align: 'center',
            sortable: true,
          },
          {
            name: 'deptNm',
            field: 'deptNm',
            label: '부서',
            align: 'center',
            sortable: true,
          },
          {
            name: 'userNm',
            field: 'userNm',
            label: '이름',
            align: 'center',
            sortable: true
          },
          {
            name: 'positionNm',
            field: 'positionNm',
            label: '직책',
            align: 'center',
            sortable: true
          },
          {
            name: 'dutyNm',
            field: 'dutyNm',
            label: '직위',
            align: 'center',
            sortable: true
          },
        ],
        data: [],
        height: '362px'
      },
      grid4: {
        columns: [
          {
            name: 'plantNm',
            field: 'plantNm',
            label: '사업장',
            align: 'center',
            sortable: true,
            // rowspan: 5,
          },
          {
            name: 'test',
            field: 'test',
            label: '1',
            align: 'center',
            child: [
              {
                name: 'test1',
                field: 'test1',
                label: '2-1',
                align: 'center',
                sortable: true
              },
              {
                name: 'test2',
                field: 'test2',
                label: '2-2',
                align: 'center',
                sortable: true
              },
              {
                name: 'test3',
                field: 'test3',
                label: '2-3',
                align: 'center',
                child: [
                  {
                    name: 'qq',
                    field: 'qq',
                    label: '3-1',
                    align: 'center',
                    sortable: true
                  },
                  {
                    name: 'ww',
                    field: 'ww',
                    label: '3-2',
                    align: 'center',
                    sortable: true
                  },
                  {
                    name: 'ee',
                    field: 'ee',
                    label: '3-3',
                    align: 'center',
                    child: [
                      {
                        name: 'hh',
                        field: 'hh',
                        label: '4-1',
                        align: 'center',
                        sortable: true
                      },
                      {
                        name: 'jj',
                        field: 'jj',
                        label: '4-2',
                        align: 'center',
                        sortable: true
                      },
                    ]
                  },
                ]
              },
            ]
          },
          {
            name: 'chngStepNm',
            field: 'chngStepNm',
            label: '진행단계',
            align: 'center',
            sortable: true
          },
          {
            name: 'bizNm',
            field: 'bizNm',
            label: '제목',
            align: 'left',
            sortable: true,
            type: 'link',
          },
        ],
        data: [],
      },
      grid5: {
        columns: [
          {
            name: 'col1',
            field: 'col1',
            label: '첫번째는 expand',
            align: 'left',
            style: 'width:300px',
            sortable: true,
          },
          {
            name: 'col2',
            field: 'col2',
            label: '두번째',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'col3',
            field: 'col3',
            label: '세번째',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'col4',
            field: 'col4',
            label: '네번째',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
        ],
        data: [],
        height: '362px'
      },
      selection: 'single',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      // list setting
      this.grid.data = [
        {
          plantNm: '사업장3',
          chngStepNm: '발의',
          bizNm: '변경관리 발의',
          bizApprStepNm: '',
          chngAttNm: '정상',
          chngNum: '2021-00001',
          rqstDt: '2021-01-01',
          rqstDeptNm: '안전환경팀',
          rqstUserNm: '시스템',
          confirmDt: '',
        },
        {
          plantNm: '사업장3',
          chngStepNm: '검토(위원회)',
          bizNm: '변경관리 검토(위원회)',
          bizApprStepNm: '',
          chngAttNm: '정상',
          chngNum: '2021-00002',
          rqstDt: '2021-01-01',
          rqstDeptNm: '안전환경팀',
          rqstUserNm: '시스템',
          confirmDt: '',
        },
        {
          plantNm: '사업장3',
          chngStepNm: '실시',
          bizNm: '변경관리 실시',
          bizApprStepNm: '',
          chngAttNm: '정상',
          chngNum: '2021-00003',
          rqstDt: '2021-01-01',
          rqstDeptNm: '안전환경팀',
          rqstUserNm: '시스템',
          confirmDt: '',
        },
        {
          plantNm: '사업장3',
          chngStepNm: 'punch list',
          bizNm: '변경관리 punch list',
          bizApprStepNm: '',
          chngAttNm: '정상',
          chngNum: '2021-00004',
          rqstDt: '2021-01-01',
          rqstDeptNm: '안전환경팀',
          rqstUserNm: '시스템',
          confirmDt: '',
        },
        {
          plantNm: '사업장3',
          chngStepNm: '완료',
          bizNm: '변경관리 완료',
          bizApprStepNm: '',
          chngAttNm: '정상',
          chngNum: '2021-00005',
          rqstDt: '2021-01-01',
          rqstDeptNm: '안전환경팀',
          rqstUserNm: '시스템',
          confirmDt: '',
        },
        {
          plantNm: '사업장3',
          chngStepNm: '변경취소',
          bizNm: '변경관리 변경취소',
          bizApprStepNm: '',
          chngAttNm: '정상',
          chngNum: '2021-00006',
          rqstDt: '2021-01-01',
          rqstDeptNm: '안전환경팀',
          rqstUserNm: '시스템',
          confirmDt: '',
        },
        {
          plantNm: '사업장3',
          chngStepNm: '변경취소',
          bizNm: '변경관리 변경취소',
          bizApprStepNm: '',
          chngAttNm: '정상',
          chngNum: '2021-00006',
          rqstDt: '2021-01-01',
          rqstDeptNm: '안전환경팀',
          rqstUserNm: '시스템',
          confirmDt: '',
        },
        {
          plantNm: '사업장3',
          chngStepNm: '변경취소',
          bizNm: '변경관리 변경취소',
          bizApprStepNm: '',
          chngAttNm: '정상',
          chngNum: '2021-00006',
          rqstDt: '2021-01-01',
          rqstDeptNm: '안전환경팀',
          rqstUserNm: '시스템',
          confirmDt: '',
        },
        {
          plantNm: '사업장3',
          chngStepNm: '변경취소',
          bizNm: '변경관리 변경취소',
          bizApprStepNm: '',
          chngAttNm: '정상',
          chngNum: '2021-00006',
          rqstDt: '2021-01-01',
          rqstDeptNm: '안전환경팀',
          rqstUserNm: '시스템',
          confirmDt: '',
        },
        {
          plantNm: '사업장3',
          chngStepNm: '변경취소',
          bizNm: '변경관리 변경취소',
          bizApprStepNm: '',
          chngAttNm: '정상',
          chngNum: '2021-00006',
          rqstDt: '2021-01-01',
          rqstDeptNm: '안전환경팀',
          rqstUserNm: '시스템',
          confirmDt: '',
        },
        {
          plantNm: '사업장3',
          chngStepNm: '변경취소',
          bizNm: '변경관리 변경취소',
          bizApprStepNm: '',
          chngAttNm: '정상',
          chngNum: '2021-00006',
          rqstDt: '2021-01-01',
          rqstDeptNm: '안전환경팀',
          rqstUserNm: '시스템',
          confirmDt: '',
        },
        {
          plantNm: '사업장3',
          chngStepNm: '변경취소',
          bizNm: '변경관리 변경취소',
          bizApprStepNm: '',
          chngAttNm: '정상',
          chngNum: '2021-00006',
          rqstDt: '2021-01-01',
          rqstDeptNm: '안전환경팀',
          rqstUserNm: '시스템',
          confirmDt: '',
        },
        {
          plantNm: '사업장3',
          chngStepNm: '변경취소',
          bizNm: '변경관리 변경취소',
          bizApprStepNm: '',
          chngAttNm: '정상',
          chngNum: '2021-00006',
          rqstDt: '2021-01-01',
          rqstDeptNm: '안전환경팀',
          rqstUserNm: '시스템',
          confirmDt: '',
        },
        {
          plantNm: '사업장3',
          chngStepNm: '변경취소',
          bizNm: '변경관리 변경취소',
          bizApprStepNm: '',
          chngAttNm: '정상',
          chngNum: '2021-00006',
          rqstDt: '2021-01-01',
          rqstDeptNm: '안전환경팀',
          rqstUserNm: '시스템',
          confirmDt: '',
        },
        {
          plantNm: '사업장3',
          chngStepNm: '변경취소',
          bizNm: '변경관리 변경취소',
          bizApprStepNm: '',
          chngAttNm: '정상',
          chngNum: '2021-00006',
          rqstDt: '2021-01-01',
          rqstDeptNm: '안전환경팀',
          rqstUserNm: '시스템',
          confirmDt: '',
        },
        {
          plantNm: '사업장3',
          chngStepNm: '변경취소',
          bizNm: '변경관리 변경취소',
          bizApprStepNm: '',
          chngAttNm: '정상',
          chngNum: '2021-00006',
          rqstDt: '2021-01-01',
          rqstDeptNm: '안전환경팀',
          rqstUserNm: '시스템',
          confirmDt: '',
        },
        {
          plantNm: '사업장3',
          chngStepNm: '변경취소',
          bizNm: '변경관리 변경취소',
          bizApprStepNm: '',
          chngAttNm: '정상',
          chngNum: '2021-00006',
          rqstDt: '2021-01-01',
          rqstDeptNm: '안전환경팀',
          rqstUserNm: '시스템',
          confirmDt: '',
        },
      ];

      this.grid2.data = [
        {
          Thousand: 12345678,
          link: '클릭',
          text: 'text 입력',
          select: null,
          selectCd: null,
          textarea: 'textarea 입력',
          user: '김씨',
          userId: '1',
          check: 'O',
          date: '2021-06-21',
          dept: '안전환경팀',
          deptCd: '1',
        }
      ];

      this.grid3.data = [
        {
          plantNm: '사업장3',
          deptNm: '안전환경팀',
          userId: '1',
          userNm: '홍길동',
          positionNm: '팀장',
          dutyNm: '부장',
        },
        {
          plantNm: '사업장3',
          deptNm: '안전환경팀',
          userId: '2',
          userNm: '김길동',
          positionNm: '팀원',
          dutyNm: '대리',
        },
        {
          plantNm: '사업장3',
          deptNm: '안전환경팀',
          userId: '3',
          userNm: '최길동',
          positionNm: '팀원',
          dutyNm: '사원',
        },
      ];

      this.grid4.data = [
        {
          plantNm: '사업장3',
          chngStepNm: '발의',
          bizNm: '변경관리 발의',
          test1: 'test1',
          test2: 'test2',
          qq: 'qq',
          ww: 'wqw',
          hh: 'hh',
          jj: 'jj',
        },
        {
          plantNm: '사업장3',
          chngStepNm: '검토(위원회)',
          bizNm: '변경관리 검토(위원회)',
          test1: 'test12',
          test2: 'test22',
          qq: 'qq2',
          ww: 'wqw2',
          hh: 'hh2',
          jj: 'jj2',
        },
        {
          plantNm: '사업장3',
          chngStepNm: '실시',
          bizNm: '변경관리 실시',
          test1: 'test13',
          test2: 'test23',
          qq: 'qq3',
          ww: 'wqw3',
          hh: 'hh3',
          jj: 'jj3',
        },
        {
          plantNm: '사업장3',
          chngStepNm: '실시',
          bizNm: '변경관리 실시',
          test1: 'test13',
          test2: 'test23',
          qq: 'qq3',
          ww: 'wqw3',
          hh: 'hh3',
          jj: 'jj3',
        },
        {
          plantNm: '사업장3',
          chngStepNm: '실시',
          bizNm: '변경관리 실시',
          test1: 'test13',
          test2: 'test23',
          qq: 'qq3',
          ww: 'wqw3',
          hh: 'hh3',
          jj: 'jj3',
        },
        {
          plantNm: '사업장3',
          chngStepNm: '실시',
          bizNm: '변경관리 실시',
          test1: 'test13',
          test2: 'test23',
          qq: 'qq3',
          ww: 'wqw3',
          hh: 'hh3',
          jj: 'jj3',
        },
        {
          plantNm: '사업장3',
          chngStepNm: '실시',
          bizNm: '변경관리 실시',
          test1: 'test13',
          test2: 'test23',
          qq: 'qq3',
          ww: 'wqw3',
          hh: 'hh3',
          jj: 'jj3',
        },
        {
          plantNm: '사업장3',
          chngStepNm: '실시',
          bizNm: '변경관리 실시',
          test1: 'test13',
          test2: 'test23',
          qq: 'qq3',
          ww: 'wqw3',
          hh: 'hh3',
          jj: 'jj3',
        },
        {
          plantNm: '사업장3',
          chngStepNm: '실시',
          bizNm: '변경관리 실시',
          test1: 'test13',
          test2: 'test23',
          qq: 'qq3',
          ww: 'wqw3',
          hh: 'hh3',
          jj: 'jj3',
        },
        {
          plantNm: '사업장3',
          chngStepNm: '실시',
          bizNm: '변경관리 실시',
          test1: 'test13',
          test2: 'test23',
          qq: 'qq3',
          ww: 'wqw3',
          hh: 'hh3',
          jj: 'jj3',
        },
        {
          plantNm: '사업장3',
          chngStepNm: '실시',
          bizNm: '변경관리 실시',
          test1: 'test13',
          test2: 'test23',
          qq: 'qq3',
          ww: 'wqw3',
          hh: 'hh3',
          jj: 'jj3',
        },
        {
          plantNm: '사업장3',
          chngStepNm: '실시',
          bizNm: '변경관리 실시',
          test1: 'test13',
          test2: 'test23',
          qq: 'qq3',
          ww: 'wqw3',
          hh: 'hh3',
          jj: 'jj3',
        },
        {
          plantNm: '사업장3',
          chngStepNm: '실시',
          bizNm: '변경관리 실시',
          test1: 'test13',
          test2: 'test23',
          qq: 'qq3',
          ww: 'wqw3',
          hh: 'hh3',
          jj: 'jj3',
        },
        {
          plantNm: '사업장3',
          chngStepNm: '실시',
          bizNm: '변경관리 실시',
          test1: 'test13',
          test2: 'test23',
          qq: 'qq3',
          ww: 'wqw3',
          hh: 'hh3',
          jj: 'jj3',
        },
      ];
      this.grid5.data = [
        {
          pid: null,
          id: 1,
          col1: '첫번째1',
          col2: '두번째1',
          col3: '세번째1',
          col4: '네번째1',
        },
        {
          pid: null,
          id: 2,
          col1: '첫번째2',
          col2: '두번째2',
          col3: '세번째2',
          col4: '네번째2',
        },
        {
          pid: 1,
          id: 3,
          col1: '첫번째3',
          col2: '두번째3',
          col3: '세번째3',
          col4: '네번째3',
        },
        {
          pid: 1,
          id: 4,
          col1: '첫번째4',
          col2: '두번째4',
          col3: '세번째4',
          col4: '네번째4',
        },
        {
          pid: null,
          id: 5,
          col1: '첫번째5',
          col2: '두번째5',
          col3: '세번째5',
          col4: '네번째5',
        },
        {
          pid: 5,
          id: 6,
          col1: '첫번째6',
          col2: '두번째6',
          col3: '세번째6',
          col4: '네번째6',
        },
        {
          pid: 6,
          id: 7,
          col1: '첫번째7',
          col2: '두번째7',
          col3: '세번째7',
          col4: '네번째7',
        },
      ];
    },
    linkClick() {
      window.alert('링크클릭')
    },
    select() {
      let selectData = this.$refs['userTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된게 없습니다.', // 사용자를 먼저 지정하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        let text = '선택한 data는\n\r';
        this.$_.forEach(selectData, item => {
          text += item.userNm + ' ';
        });
        text += '\n\r입니다.'
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: text,
          type: 'success', // success / info / warning / error
        });
      }
    },
  }
};
</script>
