var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-table",
        {
          attrs: {
            title: "자동으로 높이는 위아래 맞춰서 setting",
            columns: _vm.grid.columns,
            data: _vm.grid.data,
            merge: _vm.grid.merge,
          },
        },
        [_c("template", { slot: "table-button" }, [_vm._v(" 버튼 영역 ")])],
        2
      ),
      _c("c-table", {
        staticClass: "q-pt-md",
        attrs: {
          title: "높이 직접 setting setting",
          gridHeight: _vm.grid.height,
          columns: _vm.grid.columns,
          data: _vm.grid.data,
          merge: _vm.grid.merge,
        },
      }),
      _c("c-table", {
        staticClass: "q-pt-md",
        attrs: {
          title: "컴포넌트 넣기",
          gridHeight: _vm.grid2.height,
          columns: _vm.grid2.columns,
          data: _vm.grid2.data,
        },
        on: { linkClick: _vm.linkClick },
      }),
      _c(
        "c-table",
        {
          ref: "userTable",
          staticClass: "q-pt-md",
          attrs: {
            title: "선택 목록",
            columns: _vm.grid3.columns,
            data: _vm.grid3.data,
            gridHeight: _vm.grid3.height,
            selection: _vm.selection,
            rowKey: "userId",
          },
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c("q-toggle", {
                attrs: {
                  label: _vm.selection,
                  color: "primary",
                  "keep-color": "",
                  "false-value": "single",
                  "true-value": "multiple",
                },
                model: {
                  value: _vm.selection,
                  callback: function ($$v) {
                    _vm.selection = $$v
                  },
                  expression: "selection",
                },
              }),
              _vm._v("  "),
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _c("c-btn", {
                    attrs: { label: "선택", icon: "check" },
                    on: { btnClicked: _vm.select },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-table", {
        staticClass: "q-pt-md",
        attrs: {
          title: "멀티 헤더",
          gridHeight: _vm.grid4.height,
          columns: _vm.grid4.columns,
          data: _vm.grid4.data,
          merge: _vm.grid.merge,
        },
      }),
      _c("c-tree-table", {
        staticClass: "q-pt-md",
        attrs: {
          title: "tree 테이블",
          parentProperty: "pid",
          customID: "id",
          gridHeight: _vm.grid5.height,
          columns: _vm.grid5.columns,
          data: _vm.grid5.data,
          expandAll: true,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }